<template>
  <el-container>
    <el-header height="220">
      <el-row>
        <el-select
          v-model="queryInfo.subjectId"
          clearable
          placeholder="请选择试卷"
          style="margin-left: 5px"
          @change="paperChange"
        >
          <el-option
            v-for="item in allPapers"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          />
        </el-select>
      </el-row>
    </el-header>

    <el-main>
      <el-table
        :data="dataList"
        border
        style="width: 100%"
      >
        <el-table-column
          fixed="left"
          label="No"
          type="index"
        />
        <el-table-column
          prop="name"
          label="试卷名称"
          width="150"
        />
        <el-table-column
          prop="examTime"
          label="考试时间"
          width="150"
        />
        <el-table-column
          prop="totalScore"
          label="考试总分"
        />
        <el-table-column
          prop="student"
          label="参考学生"
        />
        <el-table-column
          fixed="right"
          label="操作"
          width="320"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="warning"
              @click="markPaper(scope.$index, scope.row)"
            >阅卷</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :small="screenWidth <= 768"
        layout="prev, pager, next"
        :page-size="pageSize"
        :current-page="currentPage"
        :total="totalSize"
        @current-change="handleCurrentChange"
        @prev-click="handleCurrentChange"
        @next-click="handleCurrentChange"
      />
    </el-main>
  </el-container>
</template>

<script>
import {getSubjectKV, getExamMarkList, getPaperKeyValues} from '@/api/exam'

export default {
  name: 'ExamPaper',
  data() {
    return {
      // 屏幕宽度, 为了控制分页条的大小
      screenWidth: document.body.clientWidth,
      currentPage: 1,
      pageSize: 20,
      totalSize: 0,
      dataList: [],
      // **********************************************************************
      queryInfo: {
        pageNumber: 1,
        pageSize: 10,
        paperId: null,
        marked: false
      },
      allPapers: [],
      allSubject: []
    }
  },
  created() {
    document.title = '阅卷管理'
    this.getData(this.queryInfo)
    this.getPapers()
  },
  methods: {
    handleCurrentChange(pageNumber) {
      this.currentPage = pageNumber
      this.queryInfo.pageNumber = this.currentPage
      this.queryInfo.pageSize = this.pageSize
      this.getData(this.queryInfo)
      // 回到顶部
      scrollTo(0, 0)
    },
    getData(queryInfo) {
      getExamMarkList(queryInfo).then(resp => {
        if (resp.code === 0) {
          this.dataList = resp.data.list
          this.totalSize = resp.data.totalSize
        } else {
          this.$notify({
            title: '提示',
            message: resp.msg,
            type: 'warning',
            duration: 3000
          })
        }
      }).catch(error => {
        this.$notify({
          title: '提示',
          message: error.message,
          type: 'error',
          duration: 3000
        })
      })
    },
    getPapers() {
      getPaperKeyValues().then(resp => {
        if (resp.code === 0) {
          this.allPapers = resp.data
        }
      })
    },
    // 试卷变化
    paperChange(val) {
      this.queryInfo.paperId = val
      this.queryInfo.pageNumber = this.currentPage
      this.queryInfo.pageSize = this.pageSize
      this.getData(this.queryInfo)
    },
    markPaper(index, row) {
      const routeData = this.$router.resolve({ path: '/exam/mark/' + row.resultId })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style>
</style>
